<template>
  <router-view :key="$route.fullPath"></router-view>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>

</style>
